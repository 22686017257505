@import '~app/mixins';

$border-size: 1px;

.loading {
  pointer-events: none;
}

a.link-default,
a.link-blue,
a.link-red,
a.border-blue,
a.border-default,
a.border-grey,
a.button-blue,
button.link-default,
button.link-blue,
button.link-red,
button.border-red,
button.border-blue,
button.border-default,
button.border-grey,
button.button-blue,
button.button-transparent,
div.link-default,
div.link-blue,
div.link-red,
div.border-red,
div.border-blue,
div.border-default,
div.border-grey,
div.button-blue,
div.button-transparent {
  transition: all $transition-delay;
  display: inline-block;
  padding: 5px 16px;
  position: relative;
  cursor: pointer;

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  svg use {
    transition: all $transition-delay;
  }

  .text {
    display: block;
  }

  .spinner {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    rect {
      fill: #5394f5;
    }

    &.white {
      rect {
        fill: #fff;
      }
    }
  }

  &.loading {
    .text {
      visibility: hidden;
    }
  }

  &.large {
    font-weight: bold;
    padding: 8px 20px;
  }

  &.middle {
    font-weight: normal;
    font-size: 14px;
    padding: 4px 20px;
  }

  &.small {
    font-size: 12px;
  }

  &.extra-small {
    padding: 0 10px;
  }

  &:hover,
  &:focus {
    text-decoration: none !important;
  }
}

.link-default {
  color: $color-grey;
  border: $border-size solid transparent;
  background: none;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  svg use {
    fill: $color-grey;
  }

  &:hover,
  &:focus {
    @include primary-color-dark();

    svg use {
      @include primary-fill-color-dark();
    }
  }
}

.link-blue {
  border: $border-size solid transparent;
  background: none;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  @include primary-color();

  svg use {
    @include primary-fill-color();
  }

  &:hover,
  &:focus {
    @include primary-color-dark();

    svg use {
      @include primary-fill-color-dark();
    }
  }
}

.link-red {
  color: $color-red;
  border: $border-size solid transparent;
  background: none;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  svg use {
    fill: $color-red;
  }

  &:hover,
  &:focus {
    $color-hover: darken($color-red, 20%);

    color: $color-hover;

    svg use {
      fill: $color-hover;
    }
  }
}

.border-blue {
  border: $border-size solid transparent;
  border-radius: 100px;
  background: $color-white;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  @include primary-color();
  @include primary-border-color();

  svg use {
    @include primary-fill-color();
  }

  &:hover,
  &:focus {
    @include primary-color-dark();
    @include primary-border-color-dark();

    svg use {
      @include primary-fill-color-dark();
    }
  }
}

.border-default {
  border: $border-size solid transparent;
  color: $color-grey;
  border-radius: 100px;
  background: $color-white;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  @include primary-border-color();

  svg use {
    fill: $color-grey;
  }

  &:hover,
  &:focus {
    @include primary-color-dark();
    @include primary-background-color-light();

    svg use {
      @include primary-fill-color-dark();
    }
  }
}

.border-red {
  border: $border-size solid $color-red;
  color: $color-red;
  border-radius: 100px;
  background: $color-white;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  svg use {
    fill: currentColor;
  }

  &:hover,
  &:focus {
    $color-hover: darken($color-red, 20%);

    color: $color-white;
    background: $color-red;
  }
}

.border-grey {
  border: $border-size solid $color-filter-border;
  color: $color-filter-border;
  border-radius: 100px;
  background: $color-white;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  svg use {
    fill: $color-filter-border;
  }

  &:hover,
  &:focus {
    @include primary-color-dark();
    @include primary-border-color-dark();

    svg use {
      @include primary-fill-color-dark();
    }
  }
}

.button-blue {
  border: $border-size solid transparent;
  color: $color-white;
  border-radius: 100px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  @include primary-border-color();
  @include primary-background-color();

  svg use {
    fill: $color-white;
  }

  &:hover,
  &:focus {
    @include primary-background-color-dark();

    svg use {
      fill: $color-white;
    }
  }
}

.button-transparent {
  border: $border-size solid transparent;
  color: $color-grey;
  border-radius: 100px;
  background: transparent;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  svg use {
    fill: $color-grey;
  }

  &:hover,
  &:focus {
    @include primary-color-dark();

    svg use {
      @include primary-fill-color-dark();
    }
  }
}

.import {
  &Button {
    display: inline-block;
  }

  &Input {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

